<!-- Últimas remesas -->

<template>
  <div class="cobrosPagosUltimasRemesas" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>

        <template v-slot:controles="{}">
          <div class="columna">

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">
              <div class="contenedor">

                <!-- Controles del Mto -->
                <div class="columna" style="width:610px;padding-top:10px">

                  <!-- Grid -->
                  <baseGridMD
                    :store="storeName"
                    :headers="headerGrid"
                    :items-per-page=-1
                    dense
                    show-select
                    fixed-header
                    hide-default-footer
                    height="300px"
                    @onEvent="execAccion">

                      <!-- Slot botonera Top -->
                      <template v-slot:gridTop="{ }">
                        <div style="padding:10px 0 0 10px;">
                          <div style="display:flex;padding-bottom:15px">

                            <!-- Botonera -->
                            <div class="botonera">
                              <baseBtraExtra
                                :permExtra="permExtra"
                                :modulo="btExtCfg"
                                @onEvent="execAccion">
                              </baseBtraExtra>
                            </div>

                            <!-- Filtro de búsqueda -->
                            <div style="padding-left:5px;width:280px">
                              <uiText
                                v-model="ct.txt[2]"
                                :label="ct.txt[1]"
                                appendIcon="mdi-magnify"
                                @clickIcon="filtrar"
                                @Enter="filtrar">
                              </uiText>
                            </div>

                          </div>
                        </div>
                      </template>
                  </baseGridMD>
                </div>
              </div>
            </v-sheet>
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraExtra, dualTemplate },
    props: {},

    data() {
      return {
        stIni: {
          api: "cobrosPagosUltimasRemesasF",
          titulo:"·Últimas Remesas Pagos",
          name: "cobrosPagosUltimasRemesasF",
          mView: '',
          pView:[],
          selection:'both' // first, preserve, both
        },

      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        this.headers = [{
          name:[],
          fields:[
            { text: "Remesa", value: "rem", width: "15%" },
            { text: "Fecha", value: "fh", width: "40%" , filtro:'fecha'},
            { text: "Cuentas", value: "num", width: "5%" },
            { text: "Fras", value: "fras", width: "5%" },
            { text: "Total", value: "total", width: "30%", filtro:'num' },
            { text: "Asiento", value: "asiento", width: "15%" }
          ]}
        ];

        this.headerGrid= this.headerSet();

        // botones extra
        this.btExtCfg.btnsAccion= [
          { accion: "verRemesa", icono: "mdi-check", title:'Ver Remesa' }
        ];

        this.$set(this.ct.txt, 2, '');

        // obtengo datos de GRID
        this.autoLoad= 'buscar';
      },


      iniDataApi() {
        this.apiArgs.sch= null;
        this.apiArgs.get= ["", 'lista', { tip:1 }, "remesas"];
      },


      //
      verRemesa() {
        if (!Object.keys(this.syncRecord).length) {
          this.$root.$alert.open('Debe seleccionar una remesa', 'info');
          return;
        }

        //this.$store.commit(this.storeName + '/modalSet', false);
        this.$emit('onVerRemesa', { rem:this.syncRecord.rem });
      },


      // filtra registros localmente
      async filtrar() {
        await this.stFiltrar(this.filtrarRemesa, {});
      },

      //
      filtrarRemesa(records) {
        let txt= this.ct.txt[2];

        return records.filter(elem=> {
          return (
              elem.rem.toLowerCase().includes(txt.toLowerCase())
            )
        });

      },

    },


    computed: {

      // devuelve schema particular
      sch() {
        return {
          txt: [ 'txt', 'Búsqueda (Remesa)', "" ]
        }
      },

    },

  };
</script>
